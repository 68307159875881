import $ from 'jquery';

const NAVIGATION_TOGGLED_CLASS = 'navigation--menu-shown';
const SCROLL_DISTANCE_TO_SHOW_NAVIGATION = 450;

const navbarElement = $('.navigation');
const burgerElement = $('#navigation-mobile-burger');

/**
 * Toggle shown/hidden for navigation's menu.
 *
 * @param {HTMLElement} navbar Navigation bar HTML Element.
 */
function toggleNavigationBar(navbar) {
  navbar.toggleClass(NAVIGATION_TOGGLED_CLASS);
  $(document.body).toggleClass('navigation-opened');
}

burgerElement.on('click', () => {
  burgerElement.toggleClass('burger--active');
  toggleNavigationBar(navbarElement);
});

navbarElement.find('.navigation__item').on('click', () => {
  burgerElement.removeClass('burger--active');
  if ($(document.body).hasClass('navigation-opened'))
    toggleNavigationBar(navbarElement);
});

$(window).on('resize', () => {
  $('.navigation').removeClass('navigation--scrolled');
});

$(window).on('scroll', () => {
  if ($(window).scrollTop() > SCROLL_DISTANCE_TO_SHOW_NAVIGATION) {
    $('.navigation').addClass('navigation--scrolled');
    $('.menu').addClass('navigation--scrolled');
  } else {
    $('.navigation').removeClass('navigation--scrolled');
    $('.menu').removeClass('navigation--scrolled');
  }
});
