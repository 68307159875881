/* eslint-disable */
import $ from 'jquery';

var getPlural = function (count, words) {
  var cases = [2, 0, 1, 1, 1, 2];
  return words[
    count % 100 > 4 && count % 100 < 20 ? 2 : cases[Math.min(count % 10, 5)]
  ];
};

/* Shitty timer. Refactor it */
if ($('.stock').length) {
  const hoursElement = $('#timer-hours');
  const minutesElement = $('#timer-minutes');
  const hoursElementDef = $('#timer-hours-def');
  const minutesElementDef = $('#timer-minutes-def');

  function time() {
    var d1 = new Date();
    var d2 = new Date(
      d1.getFullYear(),
      d1.getMonth(),
      d1.getDate() + (d1.getHours() < 23 ? 0 : 1),
      23
    );
    var dh = d2 - d1;
    var hours = Math.floor(dh / 3600000);

    var dm = dh - 3600000 * hours;
    var min = Math.floor(dm / 60000);

    var ds = dm - 60000 * min;
    var sec = Math.floor(ds / 1000);

    var dmilli = ds - 1000 * sec;

    setTimeout(time, dmilli);

    hours = ('0' + hours).slice(-2);
    min = ('0' + min).slice(-2);
    sec = ('0' + sec).slice(-2);

    hoursElement.text(hours);
    hoursElementDef.text(getPlural(hours, ['час', 'часа', 'часов']));
    minutesElement.text(min);
    minutesElementDef.text(getPlural(min, ['минута', 'минуты', 'минут']));
  }
  time();
}
