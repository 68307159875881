import PerfectScrollbar from 'perfect-scrollbar';

/**
 * Custom Scrollbar
 *
 * @see https://www.npmjs.com/package/perfect-scrollbar
 */
const scrollbarContainers = document.querySelectorAll('.custom-scrollbar');
if (scrollbarContainers.length > 0) {
  scrollbarContainers.forEach((element) => {
    // eslint-disable-next-line no-new
    new PerfectScrollbar(element, {
      wheelSpeed: 0.5,
      wheelPropagation: true,
      swipeEasing: true,
    });

    // ps();
  });
}
