import $ from 'jquery';

const menuTogglerElement = $('.menu-toggler, .menu__close');

const closeMenu = () => {
  $('body').removeClass('menu-shown');
  menuTogglerElement.find('.burger').removeClass('burger--active');
};

const toggleMenu = () => {
  $('body').toggleClass('menu-shown');
  menuTogglerElement.find('.burger').toggleClass('burger--active');
};

menuTogglerElement.on('click', toggleMenu);
$('.menu__backdrop').on('click', closeMenu);
$('.navigation__item').on('click', closeMenu);
