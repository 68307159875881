/* Polyfill functional */
import './polyfills/isNan';
import './polyfills/assign';
import './polyfills/from';
import './polyfills/foreach';

import $ from 'jquery';
import 'popper.js';
import 'bootstrap';
import '../scss/index.scss';

import './smooth-scroll';

import './custom-scrollbar';
import './navigation';
import './menu';
import './masters';
import './reviews';
import './timer';

window.$ = $;

/* Shit happens */
const DEFAULT_PROBLEM = '';

$('#callback').on('show.bs.modal', function (event) {
  const button = $(event.relatedTarget);
  const problem = button.data('problem');
  const modal = $(this);

  modal.find('input[name="problem"]').val(problem || DEFAULT_PROBLEM);
});

function updateDeviceProps(rootSelector) {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', vh + 'px');

  const root = document.querySelector(rootSelector);
  const scrollWidth = window.innerWidth - root.clientWidth;
  document.documentElement.style.setProperty(
    '--scroll-width',
    scrollWidth + 'px'
  );
}
window.addEventListener('resize', () => {
  updateDeviceProps('body');
});
updateDeviceProps('body');
