import $ from 'jquery';
import Swiper, { Navigation, Pagination } from 'swiper';

// configure Swiper to use modules
Swiper.use([Navigation, Pagination]);

if ($('.reviews').length) {
  const SWIPER_CONFIG = {
    loop: true,
    slidesPerView: 1,
    // freeMode: true,
    navigation: {
      prevEl: '.reviews .slider__arrow--left',
      nextEl: '.reviews .slider__arrow--right',
    },
    pagination: {
      el: '.reviews .slider__pagination-bullets',
      type: 'bullets',
      bulletActiveClass: 'slider__pagination-bullet--active',
      bulletClass: 'slider__pagination-bullet',
    },
    spaceBetween: 30,
    autoHeight: false,
    breakpoints: {
      768: {
        slidesPerView: 2,
      },
      992: {
        slidesPerView: 2,
      },
      1200: {
        slidesPerView: 3,
        spaceBetween: 0,
      },
    },
    on: {
      slideChange: function slideChange(swiper) {
        $('.reviews .slider__pagination-current').text(
          `0${swiper.realIndex + 1}`
        );
      },
    },
  };

  let mySwiper = new Swiper('#reviews-slider', SWIPER_CONFIG);

  $(window).on('resize', () => {
    mySwiper.destroy();
    mySwiper = new Swiper('#reviews-slider', SWIPER_CONFIG);
  });
}
